$(document).ready(function () {
    console.log("Объектная модель готова к использованию!");

    $('.js-form').on("click", ".js-form-submit", function (e) {

        e.preventDefault();

        var form = $(this.form);

        var csrfParam = $('meta[name="csrf-param"]').attr("content");
        var csrfToken = $('meta[name="csrf-token"]').attr("content");

        var data = form.serialize();

        $.ajax({

            url: '/order/send',
            type: 'POST',
            data: data + '&_csrf=' + csrfToken ,

        }).done(function(data) {
            if(data.success) {

                // данные сохранены
                console.log('Данные сохранены');
                // очищает форму
                $(form)[0].reset();

                // ym(51922136, 'reachGoal', 'BTN_ORDER');

                // редирект
                if(data.redirect){
                    window.location.href = data.url ? data.url : "/order/index";
                }

            } else {
                // сервер вернул ошибку и не сохранил наши данные
                if(data.errors){

                    var text = '';

                    for (var key in data.errors) {
                        if (data.errors.hasOwnProperty(key)) {
                            text = text +  data.errors[key].toString();
                            text = text + '<br>';
                        }
                    }

                    $(form).find('.component-form-error').html('<p class="form-error">' + text +'</p>').addClass('visible');

                }
                // console.log(data)
            }
        })
            .fail(function () {
                // не удалось выполнить запрос к серверу
            });

        return false;

        console.log('submit');

    });


    $('[data-target]').click(function (e) {

        //console.log('goal', this.dataset.target);

        if(this.dataset.target){
           // ym(51922136, 'reachGoal', this.dataset.target);
        }

    })



});






